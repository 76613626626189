import { Injectable } from '@angular/core';
import { TimberMessageTypes } from '@shared/models/enums/enums';
import { RxModel } from '@shared/models/rx-models/interfaces/rx-model';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { filter, take, takeUntil, tap } from 'rxjs/operators';
import { AppSettingsService } from '../app-settings/app-settings.service';
import { LogParameters } from './interfaces/log.parameters';
import { HostPlatformService } from '@shared/services/host-platform.service';
import { BaseDestroyableComponent } from '@shared/base-classes/base-destroyable';
import { Timber } from '@itero/timber';
import { EnvironmentParams, LoggingSettings } from '@shared/models/environment-params';
import { ILogParameters } from '@itero/timber/interfaces/logParameters';
import { IRemoteConfig } from '@itero/timber/interfaces/config.remote';
import { AppenderType, LogLevel } from '@itero/timber/enums';
import { v4 as uuid } from 'uuid';
import { BiEvent, ISessionStartEvent, IStageReportEvent, ITags, IUserActionEvent } from './interfaces/bi.events';
import { BiAction, BiEventType, BiLocationObject, BiObject, BiObjectName, BiObjectType, BiStage } from './interfaces/bi.enums';
import { SessionType } from '@shared/models/consts';
import { Bites } from '@modules/teeth-diagram/models/bites.enum';
import { PatientModelDto } from '@shared/models/rx-models/interfaces/patient-model-dto';

@Injectable({ providedIn: 'root' })
export class LoggerService extends BaseDestroyableComponent {
	protected timber: Timber;
	protected biTimber: Timber;
	private sessionParameters: Record<string, string>;
	private rxSessionId: string;
	readonly maxDataPartLength: number;

	constructor(
		private appSettingsService: AppSettingsService,
		private shellQuery: ShellQuery,
		protected hostPlatformService: HostPlatformService
	) {
		super();
		this.createLogger(appSettingsService.loggingSetting);
		this.createBi(appSettingsService.environmentParams);
		this.updateConfigWhenUserChanges();
		this.updateConfigWhenCompanyChanges();
		this.sessionParameters = { sessionId: uuid() };
		this.maxDataPartLength = appSettingsService.loggingSetting?.maxDataPartLength ?? 7000;
	}

	static getTimberConfig(loggingSetting: LoggingSettings): IRemoteConfig {
		if (!loggingSetting) {
			return null;
		}

		const { url, appId, requiredFields, level, appenderTypes, eventType } = loggingSetting;

		return {
			url,
			appId,
			requiredFields,
			minLogLevel: LogLevel[level] ?? LogLevel.Off,
			appenderTypes: appenderTypes.filter(x => Object.values(AppenderType).includes(x)).map(x => AppenderType[x]),
			eventType
		} as IRemoteConfig;
	}

	static getBiTimberConfig(environmentParams: EnvironmentParams): IRemoteConfig {
		if (!environmentParams) {
			return null;
		}

		const { appId, requiredFields, eventType } = environmentParams.logging;

		return {
			url: environmentParams.biTimberUrl,
			appId,
			requiredFields,
			minLogLevel: LogLevel.All,
			appenderTypes: [AppenderType.BIRemote],
			eventType
		} as IRemoteConfig;
	}

	trace(message: string, parameters: LogParameters): void {
		this.logByType(message, TimberMessageTypes.trace, parameters);
	}

	debug(message: string, parameters: LogParameters): void {
		this.logByType(message, TimberMessageTypes.debug, parameters);
	}

	info(message: string, parameters: LogParameters): void {
		this.logByType(message, TimberMessageTypes.info, parameters);
	}

	warn(message: string, parameters: LogParameters): void {
		this.logByType(message, TimberMessageTypes.warn, parameters);
	}

	error(message: string, parameters: LogParameters): void {
		this.logByType(message, TimberMessageTypes.error, parameters);
	}

	fatal(message: string, parameters: LogParameters): void {
		this.logByType(message, TimberMessageTypes.fatal, parameters);
	}

	logRXData(rx: RxModel, text: string, module: string, extendedParameters?: Record<string, string>): any {
		const rxForLog: RxModel = {
			...rx,
			Teeth: undefined,
			Bridges: undefined,
			Patient: { UID: rx.Patient?.UID } as PatientModelDto,
			Signature: undefined,
			Notes: undefined,
			NotesArray: undefined,
			TechnicalNotes: undefined,
			AligntechNotes: undefined,
			AlignTechNotesArray: undefined
		};
		const currLength = JSON.stringify(rx).length;
		const hasRemoteTimberLog = this.appSettingsService.loggingSetting.appenderTypes.some(t => AppenderType[t] === AppenderType.Remote);

		if (hasRemoteTimberLog && currLength > this.maxDataPartLength) {
			this.logRxParts(this.splitData(JSON.stringify(rxForLog)), text, 'rx', module, extendedParameters);
			this.logRxParts(this.splitData(JSON.stringify(rx.Bridges)), text, 'Bridges', module, extendedParameters);
			this.logRxParts(this.splitData(JSON.stringify(rx.Teeth)), text, 'Teeth', module, extendedParameters);
			this.logRxParts(this.splitData(rx.Notes), text, 'Notes', module, extendedParameters);
			this.logRxParts(this.splitData(rx.AligntechNotes), text, 'AligntechNotes', module, extendedParameters);

			return rxForLog;
		}
		this.info(`${text}: ${JSON.stringify(rxForLog)}`, {
			module,
			extendedParameters
		});

		return rxForLog;
	}

	sendRestoMultiBiteBi(value: boolean, name: Bites): void {
		this.userActionEvent({
			locationParentObject: BiLocationObject.RxContainer,
			objectName: name,
			action: BiAction.Clicked,
			objectType: BiObjectType.CheckBox,
			selectedValue: String(!!value),
			automationId: ''
		});
	}

	sendRestoMultiBiteToggleBi(value: boolean): void {
		this.userActionEvent({
			locationParentObject: BiLocationObject.RxContainer,
			objectName: BiObjectName.MultiBiteRestoScan,
			action: BiAction.Clicked,
			objectType: BiObjectType.ToggleButton,
			selectedValue: String(!!value),
			automationId: ''
		});
	}

	sendUpdateAttachmentLaterBi(value: boolean): void {
		this.userActionEvent({
			locationParentObject: BiLocationObject.RxContainer,
			objectName: BiObjectName.IsDraft,
			action: BiAction.Clicked,
			objectType: BiObjectType.ToggleButton,
			selectedValue: String(!!value),
			automationId: ''
		});
	}

	sendSaveRxBiLogs(rx: RxModel): void {
		const { IsDraft: isDraft, MultiBiteRestoScan: multiBiteRestoScan } = rx;

		this.stageReportEvent({
			object: BiObject.Attachments,
			stage: BiStage.Save,
			previous: 'draft',
			description: BiObjectName.IsDraft,
			trigger: String(!!isDraft)
		});

		this.stageReportEvent({
			object: BiObject.ScanOptions,
			stage: BiStage.Save,
			previous: 'draft',
			description: multiBiteRestoScan?.Includes?.join(', ') || '',
			trigger: ''
		});
	}

	setSessionParameters(sessionId: string): void {
		if (!!sessionId) {
			this.sessionParameters = { sessionId };
		}
	}

	// Bi Methods

	sessionStartEvent(user: number, companyId: number, rxSessionId?: string): void {
		this.rxSessionId = rxSessionId;
		this.sendBiEvent({
			event: {
				externalSessionId: this.sessionParameters.sessionId,
				tags: {
					userId: String(user),
					companyId: String(companyId)
				} as ITags
			} as ISessionStartEvent,
			type: BiEventType.SessionStart
		});
	}

	userActionEvent(event: IUserActionEvent): void {
		this.sendBiEvent({
			event,
			type: BiEventType.UserAction
		} as BiEvent);
	}

	stageReportEvent(event: IStageReportEvent): void {
		this.sendBiEvent({
			event,
			type: BiEventType.StageReport
		} as BiEvent);
	}

	protected createBi(environmentParams: EnvironmentParams): void {
		if (this.appSettingsService.isValidConfig('biTimberUrl')) {
			this.biTimber = new Timber(LoggerService.getBiTimberConfig(environmentParams));
		} else {
			this.biTimber = null;
			/* eslint-disable no-console */
			console.error('BI object was not created');
		}
	}

	protected sendBiEvent(biEvent: BiEvent) {
		const stringEvent = JSON.stringify(biEvent.event);

		if (this.rxSessionId) {
			this.biTimber?.biLog(
				LogLevel.All,
				{},
				{
					extendedParameters: {
						sessionId: this.rxSessionId,
						sessionType: this.hostPlatformService.isScanner ? SessionType.Desktop : SessionType.Web,
						component: 'RX',
						type: biEvent.type,
						additionalFields: JSON.parse(stringEvent)
					}
				}
			);
		}
	}

	private createLogger(loggingSettings: LoggingSettings): void {
		this.timber = new Timber(LoggerService.getTimberConfig(loggingSettings));
	}

	private logByType(message: string, messageType: TimberMessageTypes, parameters?: ILogParameters): void {
		if (this.hostPlatformService.isScanner) {
			const consoleMessage = `RxLog: ${message}, Parameters: ${JSON.stringify(parameters)}`;

			/* eslint-disable no-console */
			console.log(consoleMessage);
		}

		parameters.extendedParameters = {
			...parameters.extendedParameters,
			platform: this.hostPlatformService.hostPlatform,
			...this.sessionParameters
		};
		this.timber[messageType](message, parameters);
	}

	private updateConfigWhenUserChanges(): void {
		this.shellQuery.contactId$
			.pipe(
				filter(contactId => !!contactId),
				take(1),
				tap(contactId => this.timber.setConfig({ userId: contactId })),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}

	private updateConfigWhenCompanyChanges(): void {
		this.shellQuery.companyId$
			.pipe(
				filter(companyId => !!companyId),
				take(1),
				tap(companyId => this.timber.setConfig({ companyId })),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}

	private logRxParts(
		dataParts: string[] | null,
		message: string,
		partMessage: string,
		module: string,
		extendedParameters?: Record<string, string>
	) {
		if (dataParts == null) {
			return;
		}

		if (!extendedParameters?.traces && dataParts.length > 1) {
			extendedParameters = {
				logId: uuid()
			};
		}

		dataParts.forEach((part, i) => {
			if (!part) {
				return;
			}
			this.info(`${message} ${partMessage}${dataParts.length > 1 ? ` part ${i + 1}` : ''}: ${part}`, {
				module,
				extendedParameters
			});
		});
	}

	private splitData(data: string): string[] {
		if (!data || data === 'null') {
			return null;
		}

		if (data.length <= this.maxDataPartLength) {
			return [data];
		}

		const maxDataPart = data.substring(0, this.maxDataPartLength);
		const characterList = [',', '.', '}'];
		const indexList = characterList.map(character => maxDataPart.lastIndexOf(character)).filter(index => index > 0);

		const splitIndex = indexList.length > 0 ? Math.max(...indexList) : this.maxDataPartLength;

		const leftPart = data.substring(0, splitIndex);
		const rightPart = data.substring(splitIndex + 1);

		return [leftPart, ...this.splitData(rightPart)];
	}
}
